@font-face {
  font-family: 'LatoWebLight';
  src: url('../assets/fonts/Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
  url('../assets/fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_txt_reg.woff2') format('woff2'),
       url('../assets/fonts/Brandon_txt_reg.woff') format('woff');
}

@font-face {
  font-family: 'BrandonMedium';
  src: url('../assets/fonts/Brandon_txt_med.woff2') format('woff2'),
       url('../assets/fonts/Brandon_txt_med.woff') format('woff');
}

@font-face {
  font-family: 'BrandonBold';
  src: url('../assets/fonts/Brandon_txt_bld.woff2') format('woff2'),
       url('../assets/fonts/Brandon_txt_bld.woff') format('woff');
}
