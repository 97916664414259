// imports
@import 'variables';
@import 'mixins';
@import 'fonts';

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.modal-open {
    overflow: hidden;
  }

  .header {
    width: 100%;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    // TODO move a tag animation code to mixin: https://tobiasahlin.com/blog/css-trick-animating-link-underlines/
    .header-item {
      position: relative;
      height: 2rem;
      margin: 1.5rem;
      font-family: BrandonBold;
      font-size: 1.325rem;
      color: $white;
      text-decoration: none;

      &:hover {
        color: $white;
      }

      &:not(.selected) {
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: $white;
          visibility: hidden;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: all 0.3s ease-in-out 0s;
          transition: all 0.2s ease-in-out 0s;
        }

        &:hover:before {
          visibility: visible;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }

      &.selected {
        border-bottom: 3px solid $white;
      }
    }
  }

  .main-content-container {
    width: 90%;
    max-width: 1200px;
    height: auto;
    margin: 0 auto;
    // align-self: center;
    padding: 2rem 4rem;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.9); /* Black w/opacity/see-through */
    border: 3px solid #f1f1f1;
    border-radius: 5px;

    .background-text {
      // height: 100%;
      font-family: LatoWebLight;
      font-size: 1.325rem;
      line-height: 2rem;
      color: white;

      &.employees {
        h1 {
          margin-bottom: 0;
        }
      }

      &.employers {
        h1 {
          margin-bottom: 0;
        }
      }
    }

    #contact-form {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;

      .form-group {
        width: 100%;
      }

      input {
        height: 3rem;
        width: 30%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-family: Brandon;
        font-size: 1rem;
        border-radius: 5px;
      }

      textarea {
        width: 100%;
        padding: 0.5rem;
        font-family: Brandon;
        font-size: 1rem;
        border-radius: 5px;
      }

      label {
        display: block;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
      }

      button {
        margin: 2rem auto;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        text-transform: uppercase;
        font-family: BrandonBold;
        font-size: 1.375rem;
        background-color: $white;
        color: $black;

        &:hover {
          background-color: $grey;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    align-self: flex-end;
    display: flex;
    width: 100%;
    margin-top: 4rem;
    padding: 2rem 0;
    justify-content: space-around;
    align-items: center;
    background-color: $white;
    font-family: Brandon;
    font-size: 1.325rem;
    color: $grey;
  }
}

.commitments-wrapper {
  display: flex;
  max-width: 600px;
  justify-content: space-around;
  margin: 3rem auto;
  margin-top: 0;

  h1 {
    text-transform: uppercase;
    font-family: Brandon;
  }

  span {
    margin: 0 2rem;
    line-height: 7rem;
  }
}

//----- Mobile styles below -----//

/* Small desktop sizes */
@media screen and (max-width: 992px) {
  .app {
    .commitments-wrapper {
      margin-top: 0;
      margin-bottom: 2rem;

      h1 {
        font-size: 2rem;
      }

      span {
        margin: 0 1rem;
        line-height: 6rem;
      }
    }
  }
}

/* Tablet sizes */
@media screen and (max-width: 768px) {
  .app {
    .header {
      .header-item {
        margin: 1.25rem;
        font-size: 1.125rem;
      }
    }

    .main-content-container {
      padding: 1rem 3rem;

      #contact-form {
        input {
          width: 50%;
        }
      }
    }

    .commitments-wrapper {
      h1 {
        font-size: 1.5rem;
      }

      span {
        margin: 0 1rem;
        line-height: 5.5rem;
      }
    }
  }
}

/* Mobile sizes */
@media screen and (max-width: 575px) {
  .app {
    margin: 0;
    padding: 0;

    .header {
      display: none;
    }

    .mobile-headline {
      width: 90%;
      position: absolute;
      margin: 1.75rem 10%;
      text-align: center;
      font-size: 1.75rem;
      font-family: 'BrandonMedium';
    }

    .main-content-container {
      width: 100%;
      margin: 0;
      padding: 0 2rem;
      padding-top: 1rem;
      border: none;
      border-radius: unset;

      .background-text {
        padding-bottom: 2rem;

        p:last-child {
          margin-bottom: 3rem;
        }
      }

      #contact-form {
        input {
          width: 100%;
        }
      }
    }

    .footer {
      margin-top: 0;
      padding: 1rem 0;
      text-align: center;
      font-size: 0.875rem;
      line-height: 2rem;
    }

    .commitments-wrapper {
      display: none;
    }
  }
}

/* XS Mobile sizes */
@media screen and (max-width: 320px) {
  .app {
    .mobile-headline {
      font-size: 1.5rem;
      margin: 1.85rem 10%;
    }
  }
}
